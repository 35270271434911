import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { languages } from "../../App";
import "./AssignTimeTable.scss";
import TimTblStructure from "./TimTblStructure";
import {
  IoMdAddCircle,
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
  IoMdRemoveCircle,
} from "react-icons/io";
import MainCard from "../Common/Cards/MainCard";
import { Scrollbar } from "react-scrollbars-custom";
import CircularLoader from "../Common/Loader/CircularLoader";

const AssignTimeTable = () => {
  const [holidays, setHolidays] = useState({});
  const curLanguage = useContext(languages);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const financialYear = localStorage.getItem("AY");
  const nToken = localStorage.getItem("nToken");
  const userID = userInfo?.[0].id;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [apimssg, setApimssg] = useState();
  const [showClass, setShowClass] = useState();
  const [storeApiData, setStoreApiData] = useState();
  const [teachers, setTeachers] = useState();
  const [classSec, setClassSec] = useState([]);
  const [timetable, setTimetable] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customPeriods, setCustomPeriods] = useState([
    "1",
    "2",
    "Recess",
    "3",
    "4",
  ]);
  const [customDays, setCustomDays] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ]);

  const [recessDurations, setRecessDurations] = useState(
    Array(customPeriods.length).fill(5)
  );

  const [selectedValues, setSelectedValues] = useState({
    selectedClass: "",
    selectedSection: "",
    teacher: "",
    subject: "",
    recess: "",
  });

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
        axios
          .get(baseURL, {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          })
          .then((response) => {
            setClassSec(response.data.class_section);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleHolidayChange = (day) => (event) => {
    setHolidays((prevHolidays) => ({
      ...prevHolidays,
      [day]: event.target.checked,
    }));
    console.log(day, "flattenedTimetable");
  };
  const fetchClassData = async (classId) => {
    try {
      const baseURL = `${process.env.REACT_APP_nodewsPrefix}/viewTeacherSubject?class_name&class_id=${classId}&schoolcode=${userInfo[0]?.institution}`;
      await axios
        .get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        })
        .then((response) => {
          setStoreApiData(response.data.subject_data);
          setTeachers(
            Object.entries(response.data.subject_data).map(([id, details]) => ({
              id: id,
              name: details.name,
            }))
          );
        });
    } catch (error) {
      console.error("Error fetching class data:", error);
      return null;
    }
  };
  const handleClassSelect2 = async (selectedClassVal) => {
    setShowClass(selectedClassVal);
  };
  const handleClassSelect = async (selectedClassId) => {
    const classData = await fetchClassData(selectedClassId);
    if (classData) {
      setTimetable({});
    }
    setSelectedValues((prevValues) => ({
      ...prevValues,
      selectedClass: selectedClassId,
    }));
  };
  const handleSectionSelect = (selectedSectionId) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      selectedSection: selectedSectionId,
    }));
    setTimetable({});
  };
  const handleSelect = (selectedId, period, day, type) => {
    setTimetable((prevTimetable) => {
      const updatedTimetable = { ...prevTimetable };
      const periodIndex = customPeriods.indexOf(period);
      const dayIndex = customDays.indexOf(day);

      if (!updatedTimetable[dayIndex]) {
        updatedTimetable[dayIndex] = [];
      }

      if (type === "subject" && storeApiData) {
        const selectedSubject = Object.entries(storeApiData).find(
          ([_, details]) =>
            details.courses.some((subject) => subject.id === selectedId)
        );
        const subjectTeachers = selectedSubject
          ? [{ id: selectedSubject[0], name: selectedSubject[1].name }]
          : [];

        updatedTimetable[dayIndex][periodIndex] = {
          ...updatedTimetable[dayIndex][periodIndex],
          [type]: selectedId,
          teachers: subjectTeachers,
          day,
          period: customPeriods[periodIndex],
        };
      } else {
        updatedTimetable[dayIndex][periodIndex] = {
          ...updatedTimetable[dayIndex][periodIndex],
          [type]: selectedId,
          day,
          period: customPeriods[periodIndex],
        };
      }

      return updatedTimetable;
    });

    setSelectedValues((prevValues) => ({
      ...prevValues,
      [type]: selectedId,
    }));
  };

  const addDays = () => {
    setCustomDays((prevCustomDays) => {
      const remainingDays = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ].filter((day) => !prevCustomDays.includes(day));
      if (remainingDays.length > 0) {
        const newDay = remainingDays[0];
        return [...prevCustomDays, newDay];
      }
      return prevCustomDays;
    });

    setTimetable((prevTimetable) => {
      const updatedTimetable = { ...prevTimetable };
      const newDay = customDays[customDays.length];
      updatedTimetable[newDay] = customPeriods.map(() => ({}));
      return updatedTimetable;
    });
  };

  const removeDays = () => {
    setCustomDays((prevCustomDays) =>
      prevCustomDays.slice(0, prevCustomDays.length - 1)
    );

    setTimetable((prevTimetable) => {
      const updatedTimetable = { ...prevTimetable };
      const removedDay = customDays[customDays.length - 1];
      delete updatedTimetable[removedDay];
      return updatedTimetable;
    });
  };

  const addMoreRecess = () => {
    setCustomPeriods((prevCustomPeriods) => [...prevCustomPeriods, "Recess"]);
    setRecessDurations((prevDurations) => [...prevDurations, 5]);
  };
  const removeMoreRecess = () => {
    setCustomPeriods((prevCustomPeriods) => {
      const recessCount = prevCustomPeriods.filter(
        (period) => period === "Recess"
      ).length;

      if (recessCount > 1) {
        const lastRecessIndex = prevCustomPeriods.lastIndexOf("Recess");
        return prevCustomPeriods.filter(
          (_, index) => index !== lastRecessIndex
        );
      }

      return prevCustomPeriods;
    });
  };
  const handleRecessDurationChange = (index, value) => {
    setRecessDurations((prevDurations) =>
      prevDurations.map((duration, i) => (i === index ? value : duration))
    );
    console.log(index, value, "Updated recess duration");
  };

  const recessCount = customPeriods.filter(
    (period) => period === "Recess"
  ).length;

  const addPeriods = () => {
    setCustomPeriods((prevCustomPeriods) => {
      const nonRecessPeriods = prevCustomPeriods.filter(
        (period) => period !== "Recess"
      );
      const nextPeriodNumber = nonRecessPeriods.length + 1;
      return [...prevCustomPeriods, `${nextPeriodNumber}`];
    });

    setTimetable((prevTimetable) => {
      const updatedTimetable = { ...prevTimetable };
      Object.keys(updatedTimetable).forEach((day) => {
        updatedTimetable[day] = [...updatedTimetable[day], {}];
      });
      return updatedTimetable;
    });
  };

  const removePeriods = () => {
    setCustomPeriods((prevCustomPeriods) => {
      const lastPeriodIndex = prevCustomPeriods.length - 1;
      if (prevCustomPeriods[lastPeriodIndex] === "Recess") {
        return prevCustomPeriods.slice(0, lastPeriodIndex - 1).concat("Recess");
      }
      return prevCustomPeriods.slice(0, lastPeriodIndex);
    });

    setTimetable((prevTimetable) => {
      const updatedTimetable = { ...prevTimetable };

      Object.keys(updatedTimetable).forEach((day) => {
        const periods = updatedTimetable[day];
        const lastPeriodIndex = periods.length - 1;
        if (periods[lastPeriodIndex] === "Recess") {
          updatedTimetable[day] = periods
            .slice(0, lastPeriodIndex - 1)
            .concat("Recess");
        } else {
          updatedTimetable[day] = periods.slice(0, lastPeriodIndex);
        }
      });

      return updatedTimetable;
    });
  };

  const moveRecessForward = (recessIndex) => {
    setCustomPeriods((prevCustomPeriods) => {
      if (recessIndex < prevCustomPeriods.length - 1) {
        const updatedPeriods = [...prevCustomPeriods];
        [updatedPeriods[recessIndex], updatedPeriods[recessIndex + 1]] = [
          updatedPeriods[recessIndex + 1],
          updatedPeriods[recessIndex],
        ];
        return updatedPeriods;
      }
      return prevCustomPeriods;
    });
  };

  const moveRecessBackward = (recessIndex) => {
    setCustomPeriods((prevCustomPeriods) => {
      if (recessIndex > 0) {
        const updatedPeriods = [...prevCustomPeriods];
        [updatedPeriods[recessIndex], updatedPeriods[recessIndex - 1]] = [
          updatedPeriods[recessIndex - 1],
          updatedPeriods[recessIndex],
        ];
        return updatedPeriods;
      }
      return prevCustomPeriods;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const flattenedTimetable = customDays.reduce((acc, day) => {
      const dayIndex = customDays.indexOf(day);
      if (holidays[day] === true) {
        acc.push({
          holiday: "holiday",
          day,
          period: "holiday",
        });
      } else {
        acc = acc.concat(
          customPeriods
            .map((period, periodIndex) => {
              if (period === "Recess") {
                return {
                  duration: recessDurations[periodIndex],
                  day,
                  period: "Recess",
                };
              } else {
                const entry = timetable[dayIndex]?.[periodIndex];
                return entry ? { ...entry, day, period } : null;
              }
            })
            .filter(Boolean)
        );
      }

      return acc;
    }, []);

    const hasRequiredKeys = flattenedTimetable.some(
      (item) =>
        item.hasOwnProperty("subject") && item.hasOwnProperty("teachers")
    );
    console.log(flattenedTimetable, "flattenedTimetable");
    console.log(hasRequiredKeys, "flattenedTimetable");
    if (
      !selectedValues.selectedClass ||
      !selectedValues.selectedSection ||
      hasRequiredKeys === false
    ) {
      alert("Please select all fields");
    } else {
      const timeTblUrl = `${process.env.REACT_APP_nodewsPrefix}/saveTimeTable`;
      try {
        const response = await axios.post(
          timeTblUrl,
          {
            financial_year: financialYear,
            added_by: userID,
            class: selectedValues.selectedClass,
            section: selectedValues.selectedSection,
            timetable: flattenedTimetable,
            schoolcode: userInfo[0]?.institution,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );

        setApimssg(response.data.response);
        setOpenSnackbar(true);
        setLoading(false);
        setTimetable([]);
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="xl">
      <MainCard
        cardTitle={
          <>
            {curLanguage.timeTable}&nbsp;
            {showClass}
            {selectedValues.selectedSection}
          </>
        }
        mainPage
        additionalData={
          <TimTblStructure
            classSec={classSec}
            onSelectClass={handleClassSelect}
            onSelectClassVal={handleClassSelect2}
            onSelectSection={handleSectionSelect}
          />
        }
      >
        {loading ? (
          <CircularLoader />
        ) : (
          <form onSubmit={handleSubmit}>
            <Snackbar
              open={openSnackbar}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <SnackbarContent
                sx={{ backgroundColor: "greenColor.color" }}
                message="Timetable added successfully!"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={handleSnackbarClose}
                  >
                    OK
                  </Button>
                }
              />
            </Snackbar>
            <Scrollbar style={{ width: "100%", height: `calc(70vh - 50px)` }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className="timeTblHead">
                    <TableRow>
                      <TableCell>
                        <Typography>
                          <b>Days/Period</b>
                        </Typography>
                      </TableCell>

                      {customPeriods.map((period, index) => (
                        <TableCell component="th" scope="row" key={index}>
                          <Box
                            display={"flex"}
                            gap={0.5}
                            justifyContent={"space-between"}
                            sx={{ maxWidth: "140px" }}
                          >
                            {period === "Recess" ? (
                              <>
                                <Box display="grid">
                                  <IconButton
                                    size="small"
                                    onClick={() => moveRecessBackward(index)}
                                    title="Move Left"
                                    sx={{ p: 0 }}
                                  >
                                    <IoMdArrowDropleftCircle
                                      size={28}
                                      color="#2769aa"
                                    />
                                  </IconButton>
                                  {recessCount > 1 && (
                                    <IconButton
                                      onClick={removeMoreRecess}
                                      title="Remove Recess"
                                      sx={{ padding: 0 }}
                                    >
                                      <IoMdRemoveCircle
                                        size={28}
                                        color="#2769aa"
                                      />
                                    </IconButton>
                                  )}
                                </Box>
                                <Box display={"grid"}>
                                  <Typography>
                                    <b>Recess</b>
                                  </Typography>
                                  <FormControl
                                    fullWidth
                                    size="small"
                                    sx={{ minWidth: "80px" }}
                                  >
                                    <InputLabel
                                      id={`recess-time-${index}-label`}
                                    >
                                      Time
                                    </InputLabel>
                                    <Select
                                      labelId={`recess-time-${index}-label`}
                                      id={`recess-time-${index}`}
                                      value={recessDurations[index]}
                                      label="Time"
                                      onChange={(event) =>
                                        handleRecessDurationChange(
                                          index,
                                          event.target.value
                                        )
                                      }
                                      sx={{ fontSize: "14px" }}
                                      placeholder={recessDurations}
                                    >
                                      <MenuItem value={5}>5 min</MenuItem>
                                      <MenuItem value={10}>10 min</MenuItem>
                                      <MenuItem value={20}>20 min</MenuItem>
                                      <MenuItem value={30}>30 min</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                                <Box display="grid">
                                  <IconButton
                                    size="small"
                                    onClick={() => moveRecessForward(index)}
                                    title="Move Right"
                                    sx={{ p: 0 }}
                                  >
                                    <IoMdArrowDroprightCircle
                                      size={28}
                                      color="#2769aa"
                                    />
                                  </IconButton>
                                  <IconButton
                                    onClick={addMoreRecess}
                                    title="Add More Recess"
                                    sx={{ padding: 0 }}
                                  >
                                    <IoMdAddCircle size={28} color="#2769aa" />
                                  </IconButton>
                                </Box>
                              </>
                            ) : (
                              <>
                                <Typography>
                                  <b>Period {period}</b>
                                </Typography>
                              </>
                            )}
                          </Box>
                        </TableCell>
                      ))}

                      <Box display={"flex"}>
                        {customPeriods.length > 4 && (
                          <IconButton
                            onClick={removePeriods}
                            title="Delete Period"
                          >
                            <IoMdRemoveCircle size={28} color="#2769aa" />
                          </IconButton>
                        )}
                        <IconButton onClick={addPeriods} title="Add Period">
                          <IoMdAddCircle size={28} color="#2769aa" />
                        </IconButton>
                      </Box>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customDays.map((day, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" sx={{ py: "0" }}>
                          <b>{day}</b>
                          <br />
                          <Checkbox
                            checked={holidays[day] || false}
                            onChange={handleHolidayChange(day)}
                            sx={{ p: 0 }}
                          />{" "}
                          Holiday
                        </TableCell>

                        {customPeriods.map((period, periodindex) => (
                          <TableCell
                            sx={{
                              py: "0",
                              maxWidth: period === "Recess" ? "50px" : "140px",
                              minWidth: period === "Recess" ? "50px" : "140px",
                            }}
                            align="left"
                            key={`${index}-${periodindex}`}
                            className={`${
                              period === "Recess" ? "recessTr" : "customTr"
                            }`}
                          >
                            {holidays[day] ? (
                              <Typography textAlign="center">
                                Holiday
                              </Typography>
                            ) : (
                              <>
                                {period === "Recess" ? (
                                  <Typography textAlign="center">
                                    {period}
                                  </Typography>
                                ) : (
                                  <>
                                    <FormControl
                                      fullWidth
                                      sx={{ mb: 1, mt: 1 }}
                                      size="small"
                                      disabled={holidays[day]}
                                    >
                                      <InputLabel>Subject:</InputLabel>
                                      <Select
                                        onChange={(e) =>
                                          handleSelect(
                                            e.target.value,
                                            period,
                                            day,
                                            "subject"
                                          )
                                        }
                                        label="Subject"
                                        value={
                                          timetable[customDays.indexOf(day)]?.[
                                            customPeriods.indexOf(period)
                                          ]?.subject || ""
                                        }
                                      >
                                        {storeApiData ? (
                                          Object.values(storeApiData).flatMap(
                                            (details) =>
                                              details.courses.map((subject) => (
                                                <MenuItem
                                                  key={subject.id}
                                                  value={subject.id}
                                                >
                                                  {subject.fullname}
                                                </MenuItem>
                                              ))
                                          )
                                        ) : (
                                          <MenuItem disabled>
                                            Please select a Class
                                          </MenuItem>
                                        )}
                                      </Select>
                                    </FormControl>

                                    <FormControl
                                      fullWidth
                                      sx={{ mb: 1 }}
                                      size="small"
                                      disabled={holidays[day]} // Disable dropdowns if holiday is true
                                    >
                                      <InputLabel>Teacher:</InputLabel>
                                      <Select
                                        onChange={(e) =>
                                          handleSelect(
                                            e.target.value,
                                            period,
                                            day,
                                            "teacher"
                                          )
                                        }
                                        label="Teacher"
                                        value={
                                          timetable[customDays.indexOf(day)]?.[
                                            customPeriods.indexOf(period)
                                          ]?.teacher || ""
                                        }
                                      >
                                        {timetable[customDays.indexOf(day)]?.[
                                          customPeriods.indexOf(period)
                                        ]?.teachers?.map((teacher) => (
                                          <MenuItem
                                            key={teacher.id}
                                            value={teacher.id}
                                          >
                                            {teacher.name}
                                          </MenuItem>
                                        )) || (
                                          <MenuItem disabled>
                                            No teachers available
                                          </MenuItem>
                                        )}
                                      </Select>
                                    </FormControl>
                                  </>
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <Box
              textAlign="center"
              mt={2}
              sx={{ borderTop: "1px solid #ddd", paddingTop: "10px" }}
            >
              <Box sx={{ position: "absolute", left: "15px" }}>
                {customDays.length > 1 && (
                  <IconButton onClick={removeDays} title="Delete Days">
                    <IoMdRemoveCircle size={28} color="#2769aa" />
                  </IconButton>
                )}
                {customDays.length < 7 && (
                  <IconButton onClick={addDays} title="Add Days">
                    <IoMdAddCircle size={28} color="#2769aa" />
                  </IconButton>
                )}
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="btn"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </MainCard>
    </Container>
  );
};

export default AssignTimeTable;
