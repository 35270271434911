import update from "immutability-helper";
import { useContext, useEffect, useState } from "react";
import { MoveCard } from "./MoveCard";
import { languages } from "../../../App";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";

const AdminDashboard = () => {
  let curLanguage = useContext(languages);
  let courseinfo = localStorage.getItem("courseDetails");
  const courseinfoObject = JSON.parse(courseinfo);
  const [open, setOpen] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [customModule, setCustomModule] = useState([]);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/getConfigByDept`,
          {
            department: userInfo[0].department,
            schoolcode: userInfo[0]?.institution,
          }
        );

        const customModules = response.data.config.custommodules;
        const departmentKey = userInfo[0].department.toLowerCase();
        const departmentConfig = customModules?.[departmentKey] || {};

        setCustomModule(departmentConfig);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    fetchConfig();
  }, []);



  useEffect(() => {
    if (Object.keys(customModule).length > 0) {
      const updatedCards = [
        ...Object.keys(customModule).map((presentData, index) => ({
          id: index + 1,
          text: customModule[presentData].type,
          links: `/admissionForm?type=${customModule[presentData].type}`,
          icons: (
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/admission.svg`}
              alt="admission"
              width={110}
            />
          ),
          customclass: "green",
        })),
        {
          id: Object.keys(customModule).length + 1,
          text: curLanguage.attendance,
          links: "/adminAttendance",
          icons: (
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/attendance.svg`}
              alt="attendance"
              width={75}
            />
          ),
          customclass: "orange",
        },
        {
          id: Object.keys(customModule).length + 2,
          text: "Planner",
          links: "/Planner",
          icons: (
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/planner.svg`}
              alt="certificate"
              width={120}
            />
          ),
          customclass: "yellow",
        },
        {
          id: Object.keys(customModule).length + 3,
          text: curLanguage.certificate,
          links: "/addCertificate",
          icons: (
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/certificate.svg`}
              alt="certificate"
              width={120}
            />
          ),
          customclass: "brown",
        },
        {
          id: Object.keys(customModule).length + 4,
          text: curLanguage.calendar,
          links: "/calendar",
          icons: (
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/calendar.svg`}
              alt="certificate"
              width={120}
            />
          ),
          customclass: "purple",
        },
        {
          id: Object.keys(customModule).length + 5,
          text: "Fee",
          links: "/feeCollection",
          icons: (
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/fees.svg`}
              alt="fees"
              width={75}
            />
          ),
          customclass: "red",
        },
        {
          id: Object.keys(customModule).length + 6,
          text: curLanguage.timeTable,
          links: "/TimeTable",
          icons: (
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/timetable.svg`}
              alt="certificate"
              width={120}
            />
          ),
          customclass: "blue",
        },
        {
          id: Object.keys(customModule).length + 7,
          text: "Setting",
          links: "/setting",
          icons: (
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/usermanagement.svg`}
              alt="certificate"
              width={120}
            />
          ),
          customclass: "darkblue",
        },
        {
          id: Object.keys(customModule).length + 8,
          text: "Module",
          links: "/module",
          icons: (
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/report.png`}
              alt="certificate"
              width={120}
            />
          ),
          customclass: "lightblue",
        },
      ];

      setCards(updatedCards);
    }
  }, [customModule]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const moveCard = (dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  };

  const renderCard = (card, index) => {
    return (
      <MoveCard
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        clickHand={card.clickHandler}
        links={card.links}
        moveCard={moveCard}
        icons={card.icons}
        customclass={card.customclass}
      />
    );
  };

  return (
    <>
      <Grid container spacing={3} mb={2} sx={{ justifyContent: "center" }}>
        {cards.map((card, i) => renderCard(card, i))}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            backgroundColor: "popup.titleHeaderColor",
            color: "popup.popupheadertextcolor",
            mb: 4,
          }}
        >
          {curLanguage.attendance}
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
          }}
          className="adminInSec"
        >
          {courseinfoObject?.map((course) => {
            return (
              <Link
                rel="preload"
                key={course.id}
                to={"/attendance"}
                className="adminInList"
                state={{
                  id: course.id,
                  fullname: course.fullname,
                }}
              >
                <ListItemText
                  value={course.fullname}
                  sx={{ color: "#fff" }}
                  divider={true}
                  dense={true}
                  className="listHead"
                >
                  <div>
                    <img
                      src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/attendance2.svg`}
                      alt="attendanceIcon"
                      width={60}
                    />
                  </div>
                  {course.fullname}
                </ListItemText>
              </Link>
            );
          })}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdminDashboard;
